<template>
  <div>
    <el-card>
      <div style="float:right">
        <el-button type="primary" @click="getRealTime" disabled>实时计算</el-button>
        <el-button type="primary" style="margin:-20px 20px 10px 10px" @click="editCompanyButton">保存</el-button>
      </div>
    </el-card>
    <div>
      <div style="margin:10px">
        选择卡：
        <el-select v-model="configFrom" placeholder="请选择">
          <el-option
            v-for="item in optionGrand"
            :key="item.id"
            :label="item.subbranch"
            :value="item.id"
          ></el-option>
        </el-select>
        <span style="margin:20px"></span>当前卡余额：
        <el-input-number v-model="balance" disabled />
        <span style="margin:20px"></span>已使用次数:
        <el-input-number v-model="balance" disabled />
      </div>
      <div>
        <el-button type="primary" style="margin:-20px 10px 0px 10px" @click="addTravelFromButton">添加</el-button>
      </div>
      <div v-html=" this.suggest"></div>
      <el-table :data="travelFrom" border stripe>
        <el-table-column label="序号" align="center" width="100px">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
       
        <el-table-column label="交通方式" prop="trafficModeName">
          <template slot-scope="scope">
            <template>
              <el-form :model="scope.row">
                <el-select v-model="scope.row.trafficMode" placeholder="请选择">
                  <el-option
                    v-for="item in travelFromList"
                    :key="item.key"
                    :label="item.label"
                     :value="item.value + ''"
                  ></el-option>
                </el-select>
              </el-form>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="金额" prop="trafficMoney">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.trafficMoney" />
          </template>
        </el-table-column>
        <el-table-column label="人/次数" prop="frequency">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.frequency" />
          </template>
        </el-table-column>
         <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index, travelFrom)"
              type="text"
              size="small"
            >移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { listcompanycommuteApi,addcompanycommuteApi } from "@/api/my/company/companycommute";
import { getBankInfoList } from "@/api/capital/bank/bank";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  data() {
    return {
      queryParam: {},
      dialogVisible: false,
      balance: 0,
      isOneWay: "1",
      configFrom: {},
      optionGrand: [],
      travelFrom: [],
      radio: null,
      addFrom: {
        isApply: null,
        isTravel: null
      },
      title: null,
      suggest: null,
      xunCompanyCommuteCountVO: {
        balance: 0,
        isOneWay: 0,
        trafficVOList: {}
      },
      travelFromList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getBankList();
      this.listcompanycommuteButton();
      this.getTrankList();
    },

    //关闭
    handleClose() {
      this.dialogVisible = false;
      this.addFrom = {};
    },

    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.templateSelection = row;
    },
    getRealTime() {},
    editCompanyButton() {
      addcompanycommuteApi(this.travelFrom).then(res=>{
        this.initData();
      })
    },
    addTravelFromButton() {
      var data = {};
      this.travelFrom.push(data);
    },
    getTrankList() {
      getWorkEnumList({ enumName: "TrafficModeEnum" }).then(res => {
        this.travelFromList = res.result;
      });
    },
    getBankList() {
      getBankInfoList({ bankType: 11 }).then(res => {
        if (res.code === 500) return this.$message.error("服务器异常");
        this.optionGrand = res.result.records;
      });
    },
    listcompanycommuteButton() {
      listcompanycommuteApi(this.queryParam).then(res => {
        this.travelFrom = res.result.records;
      });
    }
  }
};
</script>
<style scoped>
</style>