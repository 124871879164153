import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunCompanyCommute'


const listcompanycommuteApi =(params)=>getAction(baseURL+"/list",params);

const addcompanycommuteApi=(params)=>postAction(baseURL+"/add",params);

const editcompanycommuteApi=(params)=>putAction(baseURL+"/edit",params);

const deletecompanycommuteByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectcompanycommuteByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

export{
    selectcompanycommuteByIdApi,
    deletecompanycommuteByIdApi,
    editcompanycommuteApi,
    addcompanycommuteApi,
    listcompanycommuteApi
}
